<template>
<div class="mb-5">
  <h3>Questionario</h3>
  <hr class="bg-primary mb-4">
  <div class="row border-bottom mb-2" v-for="(question, index) in Questions" :key="question.id">
    <div class="col-sm-12 col-md-3 border-right">{{question.question}}</div>
    <!--FORMULARIO DINAMICO  -->
    <div class="col-sm-12 col-md-9" v-show="!spinnerLoad">
      <div class="row">
        <!--SIMBOLOGIA  -->
        <div class="col-sm-12 col-md-6 border-right" v-if="question.has_option == 'options' || question.has_option == 'both'">
          <a-form-item label="Opciones" >
            <a-radio-group button-style="solid" v-model="Answers[index].answer_value"
                           :rules="{ required: true, message: 'Debe seleccionar una opción' }">
              <a-radio-button v-for="option in options" :value="option.value" :key="option.value">
                {{option.text}}
              </a-radio-button>
            </a-radio-group>
            <template v-if="!Answers[index].answer_value">
              <br>
              <small class="text-danger">Esta campo es obligatorio</small>
            </template>
          </a-form-item>
        </div>
        <div class="col-sm-12 col-md-6 border-right" v-else></div>
        <!--SIMBOLOGIA  -->
        <!--TEXTO  -->
        <div class="col-sm-12 col-md-6 border-right"  v-if="question.has_option == 'text' || question.has_option == 'both'" >
          <!--TEXT-AREA - Comentarios/Observaciones-->
          <a-form-item label="Comentarios/Observaciones" >
            <a-textarea v-model="Answers[index].answer_comments" allow-clear
                        :auto-size="{ minRows: 2, maxRows: 4 }"/>
          </a-form-item>
          <!--TEXT-AREA -Comentarios/Observaciones -->
        </div>
        <div class="col-sm-12 col-md-6 border-right" v-else></div>
        <!--TEXTO  -->
      </div>
    </div>
    <!--FORMULARIO DINAMICO  -->
    <my-spinner :load="spinnerLoad"/>
  </div>
  <!--BOTONERA  -->
  <div class="row justify-content-end mr-2 mt-4" v-if="equipmentCheckId">
    <b-button-group>
      <b-button  variant="primary" @click="Submit()" pill>
        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
          <b-icon icon="archive"/> Guardar
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Guardar">
          <b-icon icon="archive"/>
        </div>
      </b-button>
    </b-button-group>
  </div>
  <!--BOTONERA  -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'equipmentCheckForm',
  mixins: [fractalMixin],
  props: {
    checksEquipmentSourceId: {
      type: [String, Number],
      required: true,
    },
    equipmentCheckId: {
      type: [String, Number],
      required: false,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      Questions: [],
      Answers: [],
      form: this.$form.createForm(this),
      spinnerLoad: false,
    }
  },
  methods: {
    async FetchQuestion() {
      const params = {
        'filter[checksEquipmentSources]': this.checksEquipmentSourceId,
      }
      this.Questions = await this.GetResource('/checksEquipmentQuestions', params)

      this.Questions.forEach(question => {
        const answerValue = (question.has_option === 'options' || question.has_option === 'both') ? (this.options) ? this.options[0].value || '' : '' : ''
        const answerComments = ''
        this.Answers.push({
          checks_equipment_question_id: question.id,
          answer_value: answerValue,
          answer_comments: answerComments,
        })
      })
    },
    async Submit() {
      if (this.equipmentCheckId) {
        await this.AxiosPost('/customEquipmentChecksAnswers', {
          equipment_check_id: this.equipmentCheckId,
          answers: this.Answers,
        }, (resp) => {
          this.spinnerLoad = false
          this.$emit('Saved', resp)
        }, () => {
          this.spinnerLoad = false
        })
      }
    },
  },
  async mounted() {
    await this.FetchQuestion()
  },
}
</script>

<style scoped>

</style>
