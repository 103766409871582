<template>
<div class="mb-5 mt-2">
  <h3>Información General del Equipo</h3>
  <hr class="bg-primary">
  <a-descriptions :title="`Equipo:${auxiliarMaster.name}`"  bordered size="small"
                  :column="{ sm: 1, lg: 2}">
    <a-descriptions-item label="Clave">
      <strong>{{ auxiliarMaster.code }}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Planta">
      <strong>{{ auxiliarMaster.workCenters.name }}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Check List" v-if="auxiliarMaster.checksEquipmentSources">
      <strong>{{ auxiliarMaster.checksEquipmentSources.name }}</strong>
    </a-descriptions-item>
    <template v-if="isMobile()">
      <a-descriptions-item label="Fecha de ingreso a planta">
        <strong>{{ moment(auxiliarMaster.dated_at).format('DD/MM/YYYY HH:mm') }}</strong>
      </a-descriptions-item>
      <a-descriptions-item label="No.serie"> <strong>{{auxiliarMaster.serial_number}}</strong></a-descriptions-item>
      <a-descriptions-item label="Modelo"> <strong>{{auxiliarMaster.specific_data.model}}</strong></a-descriptions-item>
      <a-descriptions-item label="Año"> <strong>{{auxiliarMaster.specific_data.year}}</strong></a-descriptions-item>
      <a-descriptions-item label="Vigencia de Poliza de seguro"><strong> {{auxiliarMaster.specific_data.policy_validity}}</strong></a-descriptions-item>
      <a-descriptions-item label="Vigencia tajeta de circulación"> <strong>{{auxiliarMaster.specific_data.card_validity}}</strong></a-descriptions-item>
      <a-descriptions-item label="Capacidad de Carga"> <strong>{{auxiliarMaster.specific_data.loading_capacity}} - TON</strong></a-descriptions-item>
      <a-descriptions-item label="Estatus">
        <span v-if="auxiliarMaster.status" class="rounded p-1 bg-success text-white">Activo</span>
        <span v-else class="rounded p-1 bg-warning text-white">Inactivo</span>
      </a-descriptions-item>
    </template>
    <template v-else>
      <a-descriptions-item label="Familia"> {{auxiliarMaster.families.name}}</a-descriptions-item>
      <a-descriptions-item label="Fecha de adquisición del equipo"> {{ moment(auxiliarMaster.dated_at).format('DD/MM/YYYY HH:mm') }} </a-descriptions-item>
      <a-descriptions-item label="No.serie"> {{auxiliarMaster.serial_number}}</a-descriptions-item>
      <a-descriptions-item label="Estatus">
        <span v-if="auxiliarMaster.status" class="rounded p-1 bg-success text-white">Activo</span>
        <span v-else class="rounded p-1 bg-warning text-white">Inactivo</span>
      </a-descriptions-item>
    </template>
  </a-descriptions>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'auxiliarMasterInfo',
  mixins: [fractalMixin],
  props: {
    auxiliarMasterId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      auxiliarMaster: {
        auxiliar_master_type: 'Equipos Móviles',
        code: '',
        name: '',
        dated_at: '',
        technical_specification: '',
        serial_number: '',
        details: '',
        status: '',
        specific_data: {},
        workCenters: {},
        families: {},
      },
    }
  },
  methods: {
    // Funciones auxiliarres
    isMobile() {
      return this.auxiliarMaster.auxiliar_master_type === 'Equipos Móviles'
    },
    isLaboratory() {
      return this.auxiliarMaster.auxiliar_master_type === 'Equipos de Laboratorio'
    },
    isSecurity() {
      return this.auxiliarMaster.auxiliar_master_type === 'Equipos de Seguridad'
    },
    async Fetch() {
      const params = {
        include: 'workCenters,families,checksEquipmentSources',
      }
      this.auxiliarMaster = await this.GetResource(`/auxiliarMasters/${this.auxiliarMasterId}`, params)
    },
  },
  async mounted() {
    await this.Fetch()
  },
}
</script>

<style scoped>

</style>
