<template>
<div>
  <div class="row justify-content-end">
    <div class="col-4" v-if="Object.keys(Check).length">
      <b-button variant="info" pill block @click="Return">
        <b-icon icon="arrow-left"/> &nbsp; Regresar
      </b-button>
    </div>
  </div>
  <auxiliarMasterInfo :auxiliarMasterId="auxiliarMaster"/>
  <div class="row justify-content-center mb-2"
    v-if="Object.keys(Check).length > 0 && Check.image != undefined && Check.image != null && Check.image != []">
    <b-img :src="Check.image[0].url" fluid alt="Ilustración"/>
  </div>
  <a-steps :current="current">
    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
  </a-steps>
  <div class="steps-content mt-5">
    <template v-if="steps[current].title == 'General' ">
      <div class="mb-3">
        <h3>Detalles</h3>
        <hr class="bg-primary">
        <b-container fluid>
          <!--FORMULARIO GENERAL-->
          <a-form-model :model="resourceObj.form" :rules="formRules" ref="formEquipmentCheck"
                        layout="vertical" :wrapper-col="{ span: 24}" v-if="!spinnerLoad">
            <a-row align="middle" justify="center" class="mb-4">
              <a-col :sm="{ span: 24 }" :md="{ span:8}">
                <!--SELECT -Turno -->
                <a-form-model-item label="Turno" ref="operationShifts" prop="operationShifts" >
                  <a-select v-model="resourceObj.form.operationShifts"
                            placeholder="Seleccionar Turno" :disabled="disableFields"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in operationShifts" :key="option.id" :value="option.id" >
                      {{ option.name }}
                      ({{ moment(option.initial_time).format('HH:mm') }} -
                      {{ moment(option.final_time).format('HH:mm') }} )
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!--SELECT -Turno -->
              </a-col>
              <a-col :sm="{ span: 12 }" :md="{ span:8}">
                <!--SELECT -Inspector -->
                <a-form-model-item label="Inspector" ref="inspectors" prop="inspectors" >
                  <a-select v-model="resourceObj.form.inspectors"
                            placeholder="Seleccionar Inspector" :disabled="disableFields"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in users" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!--SELECT -Inspector -->
              </a-col>
              <a-col :sm="{ span: 12 }" :md="{ span:8}">
                <!--SELECT -Verificador -->
                <a-form-model-item label="Verificador" ref="verifiers" prop="verifiers" >
                  <a-select v-model="resourceObj.form.verifiers" allowClear
                            placeholder="Seleccionar Verificador" :disabled="disableFields"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in users" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!--SELECT -Verificador -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center" class="mb-4">
              <a-col :sm="{ span: 12 }">
                <!--DATEPICKER - Fecha de Inspección-->
                <a-form-model-item  label="Fecha de Inspección" ref="inspected_at" prop="inspected_at" >
                  <a-date-picker  v-model="resourceObj.form.inspected_at" style="width: 100%"
                                  placeholder="Fecha de Inspección" :disabled="disableFields"
                                  format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
                </a-form-model-item>
                <!--DATEPICKER - Fecha de Inspección -->
              </a-col>
              <a-col :sm="{ span: 12 }">
                <!--DATEPICKER - Fecha de verificación-->
                <a-form-model-item  label="Fecha de verificación" ref="verified_at" prop="verified_at" >
                  <a-date-picker  v-model="resourceObj.form.verified_at" style="width: 100%"
                                  placeholder="Fecha de verificación" :disabled="disableFields"
                                  format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
                </a-form-model-item>
                <!--DATEPICKER - Fecha de verificación -->
              </a-col>
            </a-row>
            <!--TEXT-AREA - Comantarios/Observaciones-->
            <a-form-model-item label="Comantarios/Observaciones" ref="comments"  prop="comments" >
              <a-textarea v-model="resourceObj.form.comments" :disabled="disableFields" :auto-size="{ minRows: 2, maxRows: 7 }"/>
            </a-form-model-item>
            <!--TEXT-AREA -Comantarios/Observaciones -->
            <h6>Datos Opcionales</h6>
            <hr class="bg-primary">
            <!--TEXT - No.Orden-->
            <a-form-model-item label="No.Orden" ref="order_number"  prop="order_number" >
              <a-input v-model.trim="resourceObj.form.order_number" :disabled="disableFields" placeholder="No.Orden" size="large"/>
            </a-form-model-item>
            <!--TEXT -No.Orden -->
            <a-row align="middle" justify="center" class="mb-4">
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--TEXT - Nombre de Operador/Laboratorista-->
                <a-form-model-item label="Nombre de Operador/Laboratorista" ref="operator_name"  prop="operator_name" >
                  <a-input v-model.trim="resourceObj.form.operator_name" :disabled="disableFields"
                           placeholder="Nombre de Operador/Laboratorista" size="large"/>
                </a-form-model-item>
                <!--TEXT -Nombre de Operador/Laboratorista -->
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--TEXT - Nombre de Técnico/Laboratorista-->
                <a-form-model-item label="Nombre de Técnico/Laboratorista" ref="technical_name"  prop="technical_name" >
                  <a-input v-model.trim="resourceObj.form.technical_name" :disabled="disableFields"
                           placeholder="Nombre de Técnico/Laboratorista" size="large"/>
                </a-form-model-item>
                <!--TEXT -Nombre de Técnico/Laboratorista -->
              </a-col>
            </a-row>
            <a-row align="middle" justify="center" class="mb-4">
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--NUMBER INPUT - Horómetro inicial-->
                <a-form-model-item label="Horómetro inicial" ref="initial_hours"  prop="initial_hours" >
                  <a-input-number v-model.number="resourceObj.form.initial_hours" :disabled="disableFields"
                                  style="width: 100%" :min="0" :step="0.1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Horómetro inicial -->
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--NUMBER INPUT - Horómetro final-->
                <a-form-model-item label="Horómetro final" ref="final_hours"  prop="final_hours" >
                  <a-input-number v-model.number="resourceObj.form.final_hours"
                                  style="width: 100%" :min="0" :step="0.1" size="large"/>
                </a-form-model-item>
                <!--NUMBER INPUT -Horómetro final -->
              </a-col>
            </a-row>

            <div class="row justify-content-end mr-2 mt-4">
              <b-button-group>
                <b-button  variant="primary" @click="Submit()" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </a-form-model>
          <my-spinner :load="spinnerLoad"/>
          <!--FORMULARIO GENERAL-->
        </b-container>
      </div>
    </template>
    <template v-else>
      <!-- Si  disableFields es verdadero, es porque el formulario principal ha sido llenado, por loque se muestran las preguntas -->
      <equipmentCheckForm v-if="!hasQuestions && disableFields"
        :checksEquipmentSourceId="checksEquipmentSource"
        :equipmentCheckId="resourceObj.form.id"
        :options="Check.options || []"
        @Saved="AnswersSaved($event)"
      />
      <span v-else-if="!hasQuestions && !disableFields">Debe contestar el formulario general</span>
      <span v-else-if="hasQuestions && !disableFields">
        No es posible tener preguntas sin tener los campos bloqueados
      </span>
      <equipmentChecksAnswers v-else :answers="CheckAnswers"/>
    </template>
  </div>
  <div class="row justify-content-center steps-action">
    <b-button  variant="info" @click="next()" pill v-if="current < steps.length - 1">
      <div class="d-none d-md-block" v-b-tooltip.hover title="Siguiente">
        <b-icon icon="arrow-right"/>
      </div>
      <div class="d-md-none" v-b-tooltip.hover title="Siguiente">
        <b-icon icon="arrow-right"/>
      </div>
    </b-button>
    <b-button  variant="default" @click="prev()" pill  v-if="current > 0">
      <div class="d-none d-md-block" v-b-tooltip.hover title="Anterior">
        <b-icon icon="arrow-left"/>
      </div>
      <div class="d-md-none" v-b-tooltip.hover title="Anterior">
        <b-icon icon="arrow-left"/>
      </div>
    </b-button>
  </div>
</div>

</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import equipmentCheckForm from '../components/equipmentCheckForm'
import auxiliarMasterInfo from '../components/auxiliarMasterInfo'
import equipmentChecksAnswers from '../components/equipmentChecksAnswers'
export default {
  name: 'equipmentChecks',
  mixins: [fractalMixin],
  components: {
    equipmentCheckForm,
    auxiliarMasterInfo,
    equipmentChecksAnswers,
  },
  data() {
    return {
      // Wizard
      current: 0,
      steps: [{ title: 'General' }, { title: 'Questionario' }],
      // Wizard
      checksEquipmentSource: this.$route.params.checksEquipmentSource,
      auxiliarMaster: this.$route.params.auxiliarMaster,
      equipmentCheck: this.$route.params.equipmentCheck,
      Check: {},
      // formulario de equipment_checks
      resourceObj: {
        resourceType: 'equipmentChecks',
        form: {
          id: null,
          checksEquipmentSources: this.checksEquipmentSource,
          auxiliarMasters: this.auxiliarMaster,
          operationShifts: undefined,
          inspectors: undefined,
          verifiers: undefined,
          inspected_at: '',
          verified_at: '',
          comments: '',
          operator_name: '',
          technical_name: '',
          initial_hours: '',
          final_hours: '',
          order_number: '',
        },
        relationships: {
          checksEquipmentSources: 'checksEquipmentSources',
          auxiliarMasters: 'auxiliarMasters',
          operationShifts: 'operationShifts',
          inspectors: 'users',
          verifiers: 'users',
        },
      },
      formRules: {
        // checksEquipmentSources: [{ required: true, message: 'El campo checksEquipmentSources es obligatorio.', trigger: 'blur' }],
        // auxiliarMasters: [{ required: true, message: 'El campo auxiliarMasters es obligatorio.', trigger: 'blur' }],
        inspectors: [{ required: true, message: 'El campo Inspector es obligatorio.', trigger: 'blur' }],
        // verifiers: [{ required: true, message: 'El campo verifiers es obligatorio.', trigger: 'blur' }],
        // operationShifts: [{ required: true, message: 'El campo Turno es obligatorio.', trigger: 'blur' }],
        inspected_at: [{ required: true, message: 'El campo Fecha de inspección es obligatorio.', trigger: 'change' }],
      },
      // COmbos
      operationShifts: [],
      users: [],
      // DOM
      spinnerLoad: false,
      disableFields: false,
      isVerfied: false,
      hasQuestions: false,
      // Respuestas
      CheckAnswers: [],
    }
  },
  methods: {
    Return() {
      if (Object.keys(this.Check).length > 0 && this.Check.equipment_type === 'Equipos de Laboratorio') {
        this.$router.push({ name: 'laboratoryEquipment', params: { type: this.Check.equipment_type } })
      } else if (Object.keys(this.Check).length > 0 && this.Check.equipment_type !== 'Equipos de Laboratorio') {
        this.$router.push({ name: 'operationEquipments', params: { type: this.Check.equipment_type } })
      }
    },
    // Wizard
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    // Wizard
    async FetchCombos() {
      this.operationShifts = await this.GetResource('operationShifts', {
        'filter[workCenters]': this.Check.workCenters.id,
        sort: 'name',
      })
      this.users = await this.GetResource('/users', { sort: 'name' })
    },
    async FetchCheck() {
      this.Check = await this.GetResource(`/checksEquipmentSources/${this.checksEquipmentSource}`, {
        include: 'workCenters',
      })
    },
    async FetchEquipmentCheck(equipmentCheck = 0) {
      let id = 0
      id = (equipmentCheck === 0) ? this.equipmentCheck : equipmentCheck

      const aux = await this.GetResource(`/equipmentChecks/${id}`, {
        include: 'checksEquipmentSources,auxiliarMasters,inspectors,verifiers,operationShifts,equipmentChecksAnswers',
      })
      this.resourceObj.form.id = id.toString()
      this.resourceObj.form.checksEquipmentSources = aux.checksEquipmentSources.id.toString()
      this.resourceObj.form.auxiliarMasters = aux.auxiliarMasters.toString()
      this.resourceObj.form.operationShifts = aux.operationShifts ? aux.operationShifts.id.toString() : undefined
      this.resourceObj.form.inspectors = aux.inspectors.id.toString()
      this.resourceObj.form.verifiers = aux.verifiers ? aux.verifiers.id.toString() : undefined
      this.resourceObj.form.inspected_at = aux.inspected_at
      this.resourceObj.form.verified_at = aux.verified_at ? aux.verified_at : ''
      this.resourceObj.form.comments = aux.comments ? aux.comments : ''
      this.resourceObj.form.operator_name = aux.operator_name ? aux.operator_name : ''
      this.resourceObj.form.technical_name = aux.technical_name ? aux.technical_name : ''
      this.resourceObj.form.initial_hours = aux.initial_hours ? aux.initial_hours : ''
      this.resourceObj.form.final_hours = aux.final_hours ? aux.final_hours : ''
      this.resourceObj.form.order_number = aux.order_number ? aux.order_number : ''
      this.disableFields = true
      this.hasQuestions = aux.equipmentChecksAnswers.length > 0
      if (aux.equipmentChecksAnswers.length > 0) {
        this.AnswersSaved(id.toString())
      }
    },
    Submit() {
      this.$refs.formEquipmentCheck.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (!this.resourceObj.form.id) {
            await this.AxiosPost('/customEquipmentChecks', {
              checksEquipmentSources: this.resourceObj.form.checksEquipmentSources,
              auxiliarMasters: this.resourceObj.form.auxiliarMasters,
              operationShifts: this.resourceObj.form.operationShifts,
              inspectors: this.resourceObj.form.inspectors,
              verifiers: this.resourceObj.form.verifiers,
              inspected_at: this.resourceObj.form.inspected_at,
              verified_at: this.resourceObj.form.verified_at,
              comments: this.resourceObj.form.comments,
              operator_name: this.resourceObj.form.operator_name,
              technical_name: this.resourceObj.form.technical_name,
              initial_hours: this.resourceObj.form.initial_hours,
              final_hours: this.resourceObj.form.final_hours,
              order_number: this.resourceObj.form.order_number,
            }, (resp) => {
              this.SuccessSave(resp)
            }, () => {
              this.spinnerLoad = false
            })
          } else {
            await this.AxiosPut(`/customEquipmentChecks/${this.resourceObj.form.id}`, this.resourceObj.form, (resp) => {
              this.SuccessCallback(resp)
            }, () => {
              this.spinnerLoad = false
            })
          }
        } else return false
      })
    },
    async SuccessSave(resp) {
      // apaga spinner
      this.spinnerLoad = false
      // window.location.href = `/check_list_equipos/${this.checksEquipmentSource}/${this.auxiliarMaster}/${resp.id}`
      this.$router.push({
        name: 'equipmentChecks',
        params: {
          checksEquipmentSource: this.checksEquipmentSource,
          auxiliarMaster: this.auxiliarMaster,
          equipmentCheck: resp.id,
        },
      })
      await this.FetchEquipmentCheck(resp.id)
    },
    async SuccessCallback() {
      // apaga spinner
      this.spinnerLoad = false
      // Si es verificado se deshabilitan los campos de verifacion
      if (this.resourceObj.form.verifiers && this.resourceObj.form.verified_at) {
        this.isVerfied = true
      }
    },
    async AnswersSaved(equipmentCheckId) {
      // request equipmentChecksAnswers a partir de $equipment_check_id
      this.CheckAnswers = await this.GetResource('equipmentChecksAnswers', {
        'filter[equipmentChecks]': equipmentCheckId,
        include: 'checksEquipmentQuestions,equipmentChecks.auxiliarMasters,equipmentIncidents',
      })
      this.hasQuestions = true
    },
  },
  async mounted() {
    await this.FetchCheck()
    if (this.equipmentCheck) {
      await this.FetchEquipmentCheck()
      this.resourceObj.form.id = this.equipmentCheck.toString()
    }
    await this.FetchCombos()
    this.resourceObj.form.checksEquipmentSources = this.checksEquipmentSource
    this.resourceObj.form.auxiliarMasters = this.auxiliarMaster
  },
}
</script>

<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
