<template>
<div class="mb-5">
  <h3>Respuestas</h3>
  <hr class="bg-primary mb-4">
  <div class="row border-bottom mb-2" v-for="(answer) in answers" :key="answer.id">
    <div class="col-sm-12 col-md-3 border-right">{{answer.checksEquipmentQuestions.question}}</div>
    <div class="col-sm-12 col-md-9">
      <div class="row">
        <div class="col-sm-12 col-md-5 border-right" v-if="answer.checksEquipmentQuestions.has_option == 'options' || answer.checksEquipmentQuestions.has_option == 'both'">
          <span class="text-secondary">
            {{answer.answer_value}}
          </span>
        </div>
        <div class="col-sm-12 col-md-5 border-right" v-else></div>
        <div class="col-sm-12 col-md-5 border-right"  v-if="answer.checksEquipmentQuestions.has_option == 'text' || answer.checksEquipmentQuestions.has_option == 'both'" >
          <span class="text-secondary">{{answer.answer_comments}}</span>
        </div>
        <div class="col-sm-12 col-md-5 border-right" v-else></div>
        <div class="col-sm-12 col-md-2 border-right">
          <b-button  variant="danger" @click="OpenModal(answer)" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Incidencia">
                  <b-icon icon="x"/> Incidencia
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Incidencia">
                  <b-icon icon="x"/>
              </div>
          </b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL -->
  <b-modal title="Incidencia" ref="modalI" size="lg" @hide="CloseModal" >
    <b-container fluid>
      <!--FORMULARIO-->
      <a-form-model :model="resourceObj.form" :rules="formRules" ref="formI"
          layout="vertical" :wrapper-col="{ span: 24}">
        <!--SELECT -Informador -->
        <a-form-model-item label="Informador" ref="users" prop="users" >
            <a-select v-model="resourceObj.form.users"
                        placeholder="Seleccionar Informador"
                        show-search :filter-option="filterOption">
                <a-select-option  v-for="option in Users" :key="option.id" :value="option.id" >
                  {{option.name}}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <!--SELECT -Informador -->
        <a-row align="middle" justify="center" class="mb-4">
          <a-col :sm="{ span: 24 }" :md="{ span:12}">
            <!--SELECT -Estatus -->
            <a-form-model-item label="Estatus" ref="status" prop="status" >
              <a-select v-model="resourceObj.form.status"
                        placeholder="Seleccionar Estatus"
                        show-search :filter-option="filterOption">
                <a-select-option  v-for="option in Estatus" :key="option.value" :value="option.text" >
                  {{option.text}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!--SELECT -Estatus -->
          </a-col>
          <a-col :sm="{ span: 24 }" :md="{ span:12}">
            <!--DATEPICKER - Fecha de Reporte-->
            <a-form-model-item  label="Fecha de Reporte" ref="verified_at" prop="verified_at" >
              <a-date-picker  v-model="resourceObj.form.verified_at" style="width: 100%"
                              placeholder="Fecha de Reporte"
                              format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
            </a-form-model-item>
            <!--DATEPICKER - Fecha de Reporte -->
          </a-col>
        </a-row>
        <!--TEXT-AREA - Comentarios/Obervaciones-->
        <a-form-model-item label="Comentarios/Obervaciones" ref="comments"  prop="comments" >
            <a-textarea v-model="resourceObj.form.comments" :auto-size="{ minRows: 2, maxRows: 7 }"/>
        </a-form-model-item>
        <!--TEXT-AREA -Comentarios/Obervaciones -->
        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2">
          <b-button-group>
            <b-button  variant="primary" @click="Submit()" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
      </a-form-model>
      <!--FORMULARIO-->
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- MODAL -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'equipmentChecksAnswers',
  mixins: [fractalMixin],
  props: {
    answers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      spinnerLoad: false,
      resourceObj: {
        resourceType: 'equipmentIncidents',
        form: {
          id: '',
          equipmentChecksAnswers: undefined,
          auxiliarMasters: undefined,
          users: '',
          status: 'Sin Atender',
          verified_at: '',
          comments: '',
        },
        relationships: ['equipmentChecksAnswers', 'auxiliarMasters', 'users'],
      },
      formRules: {
        equipmentChecksAnswers: [{ required: true, message: 'La respuesta es obligatoria.', trigger: 'blur' }],
        auxiliarMasters: [{ required: true, message: 'El equipo es obligatorio.', trigger: 'blur' }],
        users: [{ required: true, message: 'El Informador es obligatorio.', trigger: 'blur' }],
        status: [{ required: true, message: 'El estatus es obligatorio.', trigger: 'blur' }],
      },
      Users: [],
      Estatus: [{ value: 1, text: 'Sin Atender' }, { value: 2, text: 'En Proceso' }, { value: 3, text: 'Finalizado' }],
    }
  },
  methods: {
    async FetchCombos() {
      this.Users = await this.GetResource('/users', { sort: 'name' })
    },
    OpenModal(data) {
      this.$refs.modalI.show()
      // StoreCase
      this.ResetForm(data)
    },
    CloseModal() {
      this.$refs.modalI.hide()
      this.ResetForm()
    },
    ResetForm(data = {}) {
      let equipmentChecksAnswers = ''
      let auxiliarMasters = ''
      if (Object.keys(data).length === 0) {
        equipmentChecksAnswers = undefined
        auxiliarMasters = undefined
      } else {
        equipmentChecksAnswers = data.id.toString()
        auxiliarMasters = data.equipmentChecks.auxiliarMasters.id.toString()
      }
      this.resourceObj.form = {
        id: '',
        equipmentChecksAnswers: equipmentChecksAnswers,
        auxiliarMasters: auxiliarMasters,
        users: undefined,
        status: 'Sin Atender',
        verified_at: '',
        comments: '',
      }
    },
    Submit() {
      this.$refs.formI.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            // Post
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    SuccessCallback() {
      this.spinnerLoad = false
      this.CloseModal()
    },
  },
  async mounted() {
    await this.FetchCombos()
    console.log(this.blockIncidents)
  },
}
</script>

<style scoped>

</style>
